<template>
  <el-submenu
    v-if="menu.list && menu.list.length >= 1"
    :index="menu.menuId + ''"
    :popper-class="'site-sidebar--' + sidebarLayoutSkin + '-popper'"
    :popper-append-to-body="false">
    <template slot="title">
<!--      <icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>-->
      <span>{{ menu.name }}</span>
    </template>
    <sub-menu
      v-for="item in menu.list"
      :key="item.menuId"
      :menu="item"
      :dynamicMenuRoutes="dynamicMenuRoutes">
    </sub-menu>
  </el-submenu>
  <el-menu-item v-else :index="menu.menuId + ''" @click="gotoRouteHandle(menu)">
<!--    <icon-svg :name="menu.icon || ''" class="site-sidebar__menu-icon"></icon-svg>-->
    <span>{{ menu.name }}</span>
  </el-menu-item>
</template>

<script>
  import SubMenu from './main-sidebar-sub-menu'
  export default {
    name: 'sub-menu',
    props: {
      menu: {
        type: Object,
        required: true
      },
      dynamicMenuRoutes: {
        type: Array,
        required: true
      }
    },
    components: {
      SubMenu
    },
    computed: {
      sidebarLayoutSkin: {
        get () { return this.$store.state.common.sidebarLayoutSkin }
      }
    },
    methods: {
      // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
      gotoRouteHandle (menu) {
        var route = this.dynamicMenuRoutes.filter(item => item.meta.menuId === menu.menuId)
        if (route.length >= 1) {
          if(route[0].name==='universalTable')
          {
            window.sessionStorage.setItem("enterState",0)
            window.sessionStorage.setItem("tableId",route[0].meta.tableId)
            this.$router.push({name: route[0].name, query: {id: route[0].meta.tableId}})
          }
          else
          {
            this.$router.push({ name: route[0].name })
          }
        }
      }
    }
  }
</script>
<style>
	.el-submenu__title i{
		color:#ffffff !important;
	}
	.el-menu--popup-right-start,.el-menu--popup-bottom-start{
		background-color:#ffffff !important;
		box-shadow: 0px 0px 16px 0px rgba(18,88,186,0.2);
		border-radius: 4px;
	}
	.el-menu--popup-bottom-start .el-menu-item{
		color: #777777 !important;
		background-color:#ffffff !important;
	}
	.el-menu--popup-bottom-start .el-menu-item:hover{
		color: #2E7FF0 !important; 
	}
	.el-menu--popup-bottom-start .el-submenu__title:before{
		content: "";
		background-color: #2E7FF0;
		width: 3px;
		height: 14px;
		position: absolute;
		left: 0;
		top: 55%;
		margin-top: -8px; 
	}
	.el-menu--popup-bottom-start .el-submenu__title{
		color: #2E7FF0 !important;
		background-color:#ffffff !important;
	}
	.el-menu--popup-bottom-start .el-submenu__title i{
		float:right;
		margin-top:11px !important;
		color:#2E7FF0 !important;
	}
</style>
